













import {Component, Prop, Vue} from 'vue-property-decorator';
import DashboardTile from '@/models/DashboardTile';
import Tenant from '@/models/Tenant';
import {namespace} from 'vuex-class';
import {tenantStoreGetter} from '@/stores/tenant.store';

const tenantStore = namespace('tenant');

@Component({})
export default class DashboardTileComponent extends Vue {
    @Prop()
    public item!: DashboardTile;

    @tenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
    private tenant?: Tenant;

    get tile() {
        return this.item;
    }

    public dashboardTileClicked() {
        this.$router.push({name: this.tile.route, params: {tenantId: this.tenant!.id}});
    }
}
